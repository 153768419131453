<template>
  <div
      class="toolbar-container"
  >

<div :style="{'background-color': storeData.store_theme_color,'padding': '16px 20px'}">
  <div class="default-font-semi-bold" style="padding: 8px 16px; background-color: white; width: max-content; border-radius: 10px">{{storeData.store_name}}</div>
</div>
  </div>
</template>

<script>
export default {
  name: "Toolbar",
  computed: {
    storeData() {
      return this.$store.getters.storeData;
    }
  }
}
</script>

<style scoped>
.toolbar-container {
  height: max-content;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white
}
@media only screen and (min-width: 1025px) {
  .toolbar-container {
    padding-top: 0.3rem;
  }
}
</style>