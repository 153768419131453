<template>
  <div>
    <div class="chart-title">
      <h3>Order Status Comparison</h3>
    </div>
    <div class="default-font" style="width: 100%; display: flex; flex-direction: row; justify-content: center">
<!--        <v-select v-model="lineType" :items="lineTypes" style="font-size: 12px" dense height="10px" class="default-font" outlined/>-->
      <Menu v-on:itemChange="lineType = $event" :items="lineTypes"></Menu>
    </div>
    <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between; margin-top: 12px">
      <div class="bar-selector mb-2 default-font" style="width: 45%">
<!--        <v-select v-model="versusOne" :items="versusArrayOne" style="font-size: 12px" dense height="10px" class="default-font" outlined/>-->
        <Menu v-on:itemChange="versusOne = $event" :items="versusArrayOne"></Menu>
      </div>
      <span style="margin-top: 10px; font-weight: bold" class="default-font">vs</span>
      <div class="bar-selector mb-2 default-font" style="width: 45%">
<!--        <v-select v-model="versusTwo" :items="versusArrayTwo" style="font-size: 12px" dense height="10px" class="default-font" outlined/>-->
        <Menu v-on:itemChange="versusTwo = $event" :items="versusArrayTwo"></Menu>
      </div>
    </div>
    <LineChartGenerator
        :chart-data="currentChartData"
        :chart-options="chartOptions"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
    ></LineChartGenerator>
  </div>
</template>

<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy';
import {Chart as ChartJS, Title, Tooltip, Legend, LineElement,LinearScale, CategoryScale, PointElement} from 'chart.js';
import Menu from "@/components/Common/Menu";
ChartJS.register(Title, Tooltip,Legend, LineElement, CategoryScale, LinearScale, PointElement);
export default {
  name: "LineChart",
  components: {
    Menu,
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    }
  },
  data() {
    return {
      chartData: {
        labels: ['January','February','March'],
        datasets: [{
          label: 'Sample Chart',
          data: [40, 20, 12],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
      lineTypes: ["Last 3 Months", "Current Week", "Last Week"],
      statusArray: ["ACCEPTED", "DECLINED", "PENDING", "SHIPPED", "DELIVERED"],
      lineType: "Last 3 Months",
      versusOne: "ACCEPTED",
      versusTwo: "DECLINED",
      versusArrayOne: ["ACCEPTED", "PENDING", "SHIPPED", "DELIVERED"],
      versusArrayTwo: ["DECLINED", "PENDING", "SHIPPED", "DELIVERED"],
      chartDataBeingUsed: null,
      currentChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      }
    }
  },

  created() {
    this.chartDataBeingUsed = this.lineChartData.lastThreeMonths.chartData;
    this.currentChartData.labels = this.lineChartData.lastThreeMonths.chartData.labels
    this.currentChartData.datasets.push(this.lineChartData.lastThreeMonths.chartData.datasets.find(dataset => dataset.label === this.versusOne))
    this.currentChartData.datasets.push(this.lineChartData.lastThreeMonths.chartData.datasets.find(dataset => dataset.label === this.versusTwo))
  },

  watch: {
    versusOne(val) {
      this.versusArrayTwo = this.statusArray.filter(status => status !== val)
      this.updateVersusOne(val)
    },
    versusTwo(val) {
      this.versusArrayOne = this.statusArray.filter(status => status !== val)
      this.updateVersusTwo(val)
    },
    lineType(val) {
      if (val === "Last 3 Months") this.updateGraphType(val, this.lineChartData.lastThreeMonths.chartData)
      if (val === "Current Week") this.updateGraphType(val, this.lineChartData.currentWeek.chartData)
      if (val === "Last Week") this.updateGraphType(val, this.lineChartData.lastWeek.chartData)
    },
  },
  methods: {
    updateGraphType(type, typeChartData) {
      this.chartDataBeingUsed = typeChartData;
      this.currentChartData.labels = typeChartData.labels
      this.currentChartData.datasets = [];
      this.currentChartData.datasets.push(typeChartData.datasets.find(dataset => dataset.label === this.versusOne))
      this.currentChartData.datasets.push(typeChartData.datasets.find(dataset => dataset.label === this.versusTwo))
    },
    updateVersusOne(type) {
      this.$set(this.currentChartData.datasets, 0, this.chartDataBeingUsed.datasets.find(dataset => dataset.label === type))
    },
    updateVersusTwo(type) {
      this.$set(this.currentChartData.datasets, 1, this.chartDataBeingUsed.datasets.find(dataset => dataset.label === type))
    },
  },
   computed: {
    lineChartData() {
      return this.$store.getters.reportData.lineChartData;
    }
  }
}
</script>

<style scoped>

</style>