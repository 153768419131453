<template>
  <section class="error-main">
    <v-card width="100%" outlined style="border-radius: unset">
      <v-card-title style="font-size: 22px ;color: red;" class="default-font-extra-bold">ERROR ENCOUNTERED</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h4 v-html="error">
        </h4>
      </v-card-text>
      <v-card-actions>
        <v-btn href="https://zyadashop.app"  block color="#3f51b5" class="white--text mb-2">Zyadashop <v-icon small style="margin-left: 4px">{{rightArrow}}</v-icon></v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import {mdiArrowRight} from "@mdi/js";

export default {
  name: "Error",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Error 404',
    // all titles will be injected into this template
    titleTemplate: '%s  | Zyadashop - Create online store for free'
  },
  data() {
    return {
      rightArrow: mdiArrowRight
    }
  },
  computed: {
    error() {
      return this.$store.getters.error
    }
  }
}
</script>

<style scoped>

.error-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-left: -5px;
  margin-right: -5px;
}

@media only screen and (min-width: 1025px) {
  .error-main {
    width: 420px;
  }
}

</style>