<template>
<v-card width="350" elevation="2" class="default-font">
  <v-card-title>Enter Password</v-card-title>
  <v-divider></v-divider>
  <v-card-text>
    <v-text-field @change="$store.commit('setPassword', $event)" label="Enter Password" outlined>
    </v-text-field>
  </v-card-text>
  <v-card-actions>
    <v-btn @click="checkPassword" block elevation="0" color="#3f51b5" style="color: white;padding: 12px 20px">
      Generate
    </v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
export default {
  name: "PasswordEnterDialog",
  data() {
    return {
    }
  },
  methods: {
    async checkPassword() {
      await this.$store.dispatch("fetchReportData")
    }
  }
}
</script>

<style scoped>

</style>