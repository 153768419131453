<template>
<div class="bottom-nav">
  <v-item-group v-model="selectedNav" style="height: 55px;display: flex; flex-direction: row;justify-content: center; align-items: center; width: 100%" mandatory>
    <div v-for="(navItem, index) in pluginData.btmNavLabel" :key="index" style="width: 100%">
      <router-link style="text-decoration: none;width: 100%" :to="navItem.route">
        <v-item :value="navItem.value" v-slot="{ active, toggle }">
          <div @click="toggle()" class="button">
            <v-icon :color="active ? storeData.store_theme_color : 'black'">{{active ? getIcon(navItem.value) : getOutlineIcon(navItem.value)}}</v-icon>
            <span :style="{'color' : active ? storeData.store_theme_color : 'black'}">{{ navItem.title}}</span>
          </div>
        </v-item>
      </router-link>
    </div>
  </v-item-group>
</div>
</template>

<script>
import {mdiChartBox, mdiChartBoxOutline, mdiClipboardList, mdiClipboardListOutline, mdiAccountGroup, mdiAccountGroupOutline} from '@mdi/js';
export default {
  name: "BottomNav",
  data() {
    return {
      navArray: [],
      selectedNav: null,
      accountIcon: mdiAccountGroup
    }
  },
  computed: {
    storeData() {
      return this.$store.getters.storeData;
    },
    pluginData() {
      console.log(this.$store.getters.pluginData)
      return this.$store.getters.pluginData;
    }
  },
  created() {
    if (this.$route.name === 'Main') this.selectedNav = 0
    if (this.$route.name === 'Stocks') this.selectedNav = 1
    if (this.$route.name === 'Reviews') this.selectedNav = 2
  },
  methods: {
    getIcon(index) {
      console.log('v',index)
      if (index === 0) {
        return mdiChartBox
      }
      if (index === 1) {
        return mdiClipboardList
      }
      if (index === 2) {
        return mdiAccountGroup
      }
    },
    getOutlineIcon(index) {
      if (index === 0) {
        return mdiChartBoxOutline
      }
      if (index === 1) {
        return mdiClipboardListOutline
      }
      if (index === 2) {
        return mdiAccountGroupOutline
      }
    },
    hexToRgb() {
      const hex = this.storeData.store_theme_color;
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return (
          "rgba(" +
          parseInt(result[1], 16) +
          ", " +
          parseInt(result[2], 16) +
          ", " +
          parseInt(result[3], 16) +
          ",0.1)"
      );
    },
  }
}

</script>

<style scoped>
.bottom-nav {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 -1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: unset;
  z-index: 100;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 103px;
  padding: 6px 10px;
}
</style>