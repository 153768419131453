<template>
  <section class="build-screen-container">
    <div v-if="pluginData.reviews.enabled && pluginData.reviews.build_status === 2" class="build-screen_status">
      <div class="build-screen_status-img">
        <v-img width="200" :src="require('@/assets/img/building.svg')" alt="" />
      </div>
      <div style="text-align: center" class="default-font-semi-bold">
        <p class="mt-2 mb-1">Review Analysis in Progress</p>
        <p class="mb-6">Visit after some time</p>
      </div>
      <div style="width: 100%;">
        <v-progress-linear color="#3F52B5" indeterminate/>
      </div>
    </div>
    <div v-if="pluginData.reviews.enabled && pluginData.reviews.build_status === 0" class="build-screen_status">
      <div class="build-screen_status-img">
        <v-img width="200" :src="require('@/assets/img/build-failed.svg')" alt="" />
      </div>
      <div style="text-align: center" class="default-font-semi-bold">
        <p class="mt-2 mb-1">Review Analysis Failed!</p>
        <p class="mb-2 default-font-bold red--text">ERROR</p>
        <p class="mb-6">{{pluginData.reviews.build_fail_rsn}}</p>
      </div>
      <div>
        <v-progress-linear color="#3F52B5" indeterminate/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BuildStatusPage",
  computed: {
    pluginData() {
      return this.$store.getters.pluginData
    }
  }
};
</script>

<style scoped>

p {
  margin: 0;
}
.build-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 98%;
  overflow: hidden;
}

.build-screen_status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 1025px) {
  .build-screen-container {
    width: 100%;
  }
}
</style>