<template>
  <v-menu offset-y>
    <template v-slot:activator="{ attrs, on }">
      <div
          style="display: flex; cursor: pointer;align-items: center; justify-content: center; border: 1px solid #e6e6e6; padding: 6px 10px; border-radius: 6px"
          v-bind="attrs"
          v-on="on"
      >
          <span style="font-weight: 600; font-size: 14px; color: #616161">{{selectedItem}}</span>
        <v-icon size="30">{{ downSvg }}</v-icon>
      </div>
    </template>

    <v-list>
      <v-list-item @click="itemChange(item)" style="cursor: pointer" v-for="(item, index) in items" :key="index">
        <v-list-item-title>{{item}}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {mdiChevronDown} from '@mdi/js'
export default {
  name: "Menu",
  props:['items'],
  data() {
    return {
      downSvg: mdiChevronDown,
      selectedItem: null,
    }
  },
  created() {
    this.selectedItem = this.items[0]
  },
  methods: {
    itemChange(type) {
      this.selectedItem = type;
      this.$emit("itemChange", type)
    }
  }
}
</script>

<style scoped>

</style>