<template>
<!--  <v-app style="height: 100vh">-->
<!--    <div-->
<!--        v-if="dataLoading"-->
<!--        style="-->
<!--        width: 100%;-->
<!--          height: 100%;-->
<!--          background-color: #e6e6e6;-->
<!--          display: flex;-->
<!--          align-items: center;-->
<!--          justify-content: center;-->
<!--          ">-->
<!--      <v-progress-circular color="#3f51b5" indeterminate></v-progress-circular>-->
<!--    </div>-->
<!--    <div v-else class="parent-container">-->
<!--      <div class="sideText">-->
<!--        <v-card style="margin-bottom: 12px;padding: 4px; border-radius: 5px">-->
<!--          <img height="100" width="100" src="./assets/img/zyadashop.png" style="margin-bottom: -4px" />-->
<!--        </v-card>-->
<!--        <span class="default-font-semi-bold" style="font-size: 24px">Powered by zyadashop</span>-->
<!--      </div>-->

<!--      &lt;!&ndash;    V-IF PASSWORD IS CORRECT&ndash;&gt;-->

<!--      <div style="height: 100%" v-if="reportDataLoading">-->
<!--        <LinearLoader></LinearLoader>-->
<!--      </div>-->
<!--      <div v-else class="card-container">-->
<!--          <Toolbar v-if="!isPageError && (reportData || reviewsData)"></Toolbar>-->
<!--          <div v-if="!isPageError && (reportData || reviewsData)" :style="{'background-color': storeData.store_theme_color,'padding': '0 20px 16px 20px','margin-top': '-1px'}">-->
<!--            <div v-if="!isPageError && (reportData || reviewsData)" class="storeLink">-->
<!--              <span style="font-size: 14px; font-weight: 600; color: white">{{storeData.store_link}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <router-view>-->
<!--          </router-view>-->
<!--          <Footer v-if="!isPageError && (reportData || reviewsData)"></Footer>-->
<!--          &lt;!&ndash;          BOTTOM NAV COMPONENT HERE&ndash;&gt;-->
<!--          <BottomNav v-if="!isPageError && (reportData || reviewsData)"></BottomNav>-->
<!--      </div>-->

<!--      &lt;!&ndash;    MAIN CONTENT ENDS HERE&ndash;&gt;-->
<!--      <div class="sideText">-->
<!--        <div class="sideText" style="display: flex; flex-direction: column">-->
<!--          <div style=" height: 100px;width: 100px" />-->
<!--          <span class="default-font-semi-bold" style="font-size: 24px">Made with ❤️</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </v-app>-->
  <div style="height: 100vh; width: 100%">
    <div
        v-if="dataLoading"
        style="width: 100%;
          height: 100%;
          background-color: #e6e6e6;
          display: flex;
          align-items: center;
          justify-content: center;
          ">
      <v-progress-circular color="#3f51b5" indeterminate></v-progress-circular>
    </div>
    <div v-else class="parent-container">
      <div class="sideText">
        <v-card style="margin-bottom: 12px;padding: 4px; border-radius: 5px">
          <img height="100" width="100" src="./assets/img/zyadashop.png" style="margin-bottom: -4px" />
        </v-card>
        <span class="default-font-semi-bold" style="font-size: 24px">Powered by zyadashop</span>
      </div>

      <!--    V-IF PASSWORD IS CORRECT-->

      <div class="card-container">
        <v-app style="width: 100%">
          <Toolbar v-if="!isPageError && (reportData || reviewsData)"></Toolbar>
          <div v-if="!isPageError && (reportData || reviewsData)" :style="{'background-color': storeData.store_theme_color,'padding': '0 20px 16px 20px','margin-top': '-1px'}">
            <div v-if="!isPageError && (reportData || reviewsData)" class="storeLink">
              <span style="font-size: 14px; font-weight: 600; color: white">{{storeData.store_link}}</span>
            </div>
          </div>
          <router-view>
          </router-view>
          <Footer v-if="!isPageError && (reportData || reviewsData)"></Footer>
<!--          BOTTOM NAV COMPONENT HERE-->
          <BottomNav v-if="!isPageError && (reportData || reviewsData)"></BottomNav>
        </v-app>
      </div>

      <!--    MAIN CONTENT ENDS HERE-->
      <div class="sideText">
        <div class="sideText" style="display: flex; flex-direction: column">
          <div style=" height: 100px;width: 100px" />
          <span class="default-font-semi-bold" style="font-size: 24px">Made with ❤️</span>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import Main from "@/pages/Main";
import Toolbar from "@/components/Toolbar/Toolbar";
import Footer from "@/components/Footer/Footer";
import LinearLoader from "@/components/Common/LinearLoader";
import PasswordEnterDialog from "@/components/Common/PasswordEnterDialog";
import BottomNav from "@/components/BottomNav/BottomNav";

export default {
  name: 'App',
  components: {BottomNav, PasswordEnterDialog, LinearLoader, Footer, Toolbar, Main},
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Your Report',
    // all titles will be injected into this template
    titleTemplate: '%s  | Zyadashop - Create online store for free'
  },
  async created() {
    const token = this.$route.query.token

    if (!token && !this.$cookies.isKey(`${this.$route.params.storeId}_token`)) {
      this.$store.commit("setError", "Auth Failure! This error can be due to the following reasons: <br><br> <ul><li>You haven't visited the link from the zyadashop app.</li><li>You haven't updated the app.</li></ul>")
      this.$router.push(`${this.$route.params.storeId}/error`)
      return;
    }


    if (token){
      this.$cookies.set(`${this.$route.params.storeId}_token`, token, "2d")
    }

    const status = await this.$store.dispatch("fetchStoreData", this.$route.params.storeId)

    if (!status) return


    if (this.$cookies.isKey(`${this.$route.params.storeId}_token`)) {
      this.$store.commit("setToken", this.$cookies.get(`${this.$route.params.storeId}_token`))
      await this.$store.dispatch("fetchReportData", this.$route.params.storeId);
    } else {
      this.$store.commit("setError", "Session has expired. Please use link from Zyadashop app!")
      this.$router.push(`/${this.$route.params.storeId}/error`)
    }
  },
  methods: {
  },
    computed: {
      reviewsData() {
        return this.$store.getters.reviewsData;
      },
      reportData() {
        return this.$store.getters.reportData
      },
      storeData() {
        return this.$store.getters.storeData;
      },
      dataLoading() {
        return this.$store.getters.dataLoading
      },
      isPageError() {
        return this.$route.name === "Error"
      },
      reportDataLoading() {
        return this.$store.getters.reportDataLoading;
      }
    }
};
</script>
<style scoped>
.v-application {
  font-family: popins-regular;
}
.storeLink {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 20%);
  padding: 8px 12px;
  align-items: center;
  border-radius: 4px;
}
.sideText {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%;
}
@media only screen and (max-width: 1025px) {
  .sideText {
    display: none;
  }
}
@media only screen and (min-width: 1025px) {
  .parent-container {
    display: flex;
    width: 100vw;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #e6e6e6;
  }

  .card-container {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border: 1px solid #e6e6e6;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
    max-height: 100vh;
    background-color: white;
    overflow-y: auto;
    max-width: 30%;
  }
}
</style>
