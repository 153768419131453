<template>
<div class="default-font-semi-bold footer-container">
<span>Powered by zyadashop. Made with ❤️</span>
</div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer-container {
  width: 100%;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
@media only screen and (min-width: 1025px) {
  .footer-container {
    display: none;
  }
}
</style>