import Vuex from "vuex"
import Vue from "vue"
import axios from "axios";
import router from "./router/index"


Vue.use(Vuex)

const store = new Vuex.Store({

  state: {
    reportData: null,
    storeData: null,
    token: "",
    pluginData: null,
    error: "Page not found!",
    dataLoading: false,
    reviewsData: null,
    route: null,
    reportDataLoading: false,
  },

  mutations: {
    setReportData: (state, payload) => { state.reportData = payload },
    setReviewsData: (state, payload) => { state.reviewsData = payload },
    setToken: (state, payload) => { state.token = payload },
    setStoreData: (state, payload) => { state.storeData = payload },
    setError: (state, payload) => { state.error = payload },
    setDataLoading: (state, payload) => {state.dataLoading = payload},
    setReportDataLoading: (state, payload) => {state.reportDataLoading = payload},
    setPluginData: (state, payload) => {state.pluginData = payload},
    setRoute: (state, payload) => { state.route = payload },
  },

  actions: {
    fetchStoreData:  async ({commit, state}, storeId) => {
      commit("setDataLoading", true);
      try {
        const res = await axios.post("https://api.zyadashop.io/v2/getStoreDetails/basicData", {}, {
          headers: {
            storeID: storeId,
          }
        })
        if (res.data.status !== undefined && res.data.status === 0) {
          commit("setError", res.data.rsn)
           router.push(`/${storeId}/error`)
          return res.data.status
        } else if (!res.data.status) {
          commit("setError", "Server Error")
          router.push(`/${storeId}/error`)
          return 0
        }
        commit("setStoreData", res.data.basic)
        if (res.data.pluginsEnabled) commit("setPluginData", res.data.pluginsEnabled)
        router.push(state.route.path)
        return res.data.status

      } catch (e) {
        commit("setError", e.message)
        router.push(`/${storeId}/error`)
        return 0
      } finally {
        commit("setDataLoading", false)
      }
    },
    fetchReportData: async ({state,commit}, storeId) => {
      commit('setReportDataLoading', true);
      try {

        const res = await axios.post("https://api.zyadashop.io/v2/plugins/report/generateReport", {
          enabledPlugins: state.pluginData
        }, {
          headers: {
            storeID: storeId,
            Authorization: `Bearer ${state.token}`
          }
        })

        if (res.data.status !== undefined && res.data.status === 0) {
          router.push(`/${storeId}/error`)
          commit('setReportDataLoading', false);
          return commit("setError",res.data.rsn)
        } else if (!res.data.status) {
          commit("setError", "Server Error")
          commit('setReportDataLoading', false);
          return router.push(`/${storeId}/error`)
        }
        commit('setReportDataLoading', false);
        if (res.data.report)
          commit("setReportData" , res.data.report);

        if (res.data.reviews)
          commit("setReviewsData", res.data.reviews)

      } catch (e) {
        commit("setError", e.message)
        commit('setReportDataLoading', false);
        return router.push(`/${storeId}/error`)
      }
    }
  },

  getters: {
    reportData: state => state.reportData,
    token: state => state.token,
    error: state => state.error,
    storeData: state => state.storeData,
    dataLoading: state => state.dataLoading,
    reportDataLoading: state => state.reportDataLoading,
    pluginData: state => state.pluginData,
    reviewsData: state => state.reviewsData,
  }

})

export default store;