import Vue from 'vue'
import Router from 'vue-router'
import Main from "../pages/Main"
import Error from "../pages/Error";
import store from "../store"
import StockCountScreen from "@/components/StockCounts/StockCountScreen";
import GmapReviewPage from "@/components/GmapReviews/GmapReviewPage";


Vue.use(Router)

const router =  new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
  document.getElementById('app').scrollIntoView();
  return null;
  },
  routes: [
    {
      path: '/:storeId?',
      name: 'Main',
      component: Main,
    },
    {
      path: '/:storeId/reports',
      name: 'Reports',
      component: Main,
    },
    {
      path: '/:storeId/stocks',
      name: 'Stocks',
      component: StockCountScreen,
    },
    {
      path: '/:storeId/reviews',
      name: 'Reviews',
      component: GmapReviewPage,
    },
    {
      path: '/:storeId/error',
      name: 'Error',
      component: Error
    },
    {
      path: "*",
      name: "Not Found",
      component: Error,
    }
  ]
})

router.beforeEach((to, from, next) => {

  store.commit("setRoute", to)

  if (to.params.storeId === "" || to.params.storeId === undefined) {
    location.href = "https://zyadashop.app"
  }

  if (to.name === 'Error' && from.name === null) {
    next(`/${to.params.storeId}`)
  }

  next()
})

export default router
