<template>
<section style="height: 100%; background-color: #f2f7ff" class="default-font">
  <BuildStatusPage v-if="pluginData.reviews.enabled && pluginData.reviews.build_status !== 1"/>
<div style="height: 100%" v-else>
  <div v-if="reviewsData" class="reviews-container">
    <v-row style="width: 100%" dense>
      <v-card hover class="card-container">
        <div style="display: flex; flex-direction: column;align-items: flex-start; justify-content: flex-start">
          <span style="font-weight: 600;margin-bottom: 8px">{{reviewsData.data.TITLE}}</span>
          <span style="color: #86888A; font-size: 14px">{{reviewsData.data.ADDRESS}}</span>
        </div>
      </v-card>
      <v-col cols="6">
        <v-card hover v-if="reviewsData" class="card-container">
          <div style="display: flex; flex-direction: column;justify-content: center;">
            <div v-for="(reviewNumber, index) in reviewsData.data.SENTIMENTS_AGGREGATE.chartData.datasets[0].data" :key="index" style="display: flex; flex-direction: row;align-items: center; justify-content: flex-start;">
              <div style="display: flex; flex-direction: row; align-items: center; margin-bottom: 16px; justify-content: space-between">
                <div style="display: flex; align-items: center; justify-content: center">
                  <v-img height="40" width="40" :src="require('@/assets/img/' + legendArray[index].iconPath)"></v-img>
                </div>
                <span style="margin-left: 16px; font-weight: 600">X {{reviewNumber}}</span>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card hover class="card-container">
          <div style="display: flex; flex-direction: column;justify-content: center">
            <div v-for="(item, index) in legendArray" :key="index" style="display: flex; flex-direction: row;align-items: center; justify-content: flex-start; margin-bottom: 16px">
              <div style="display: flex; align-items: center; justify-content: center">
              <v-img height="40" width="40" :src="require('@/assets/img/' + item.iconPath)"></v-img>
              </div>
              <span style="margin-left: 16px; font-weight: 600">{{item.title}}</span>
            </div>
          </div>

        </v-card>
      </v-col>
    </v-row>
    <v-card hover class="card-container">
      <div style="display: flex; flex-direction: column;align-items: center; justify-content: center">
        <span style="font-weight: 600; font-size: 18px">Customer's Mood</span>
        <div style="display: flex; align-items: center; justify-content: center">
        <img height="110" width="110" style="margin: 12px" :src="require('@/assets/img/' + getEmojiLink(reviewsData.data.SENTIMENTS_AGGREGATE.overallSentiment.text))" />
        </div>
          <span style="font-weight: 600">{{reviewsData.data.SENTIMENTS_AGGREGATE.overallSentiment.text}}</span>
      </div>
    </v-card>
    <v-card hover class="card-container">
      <Bar
        :chart-options="chartOptions"
        :chart-data="reviewsData.data.SENTIMENTS_AGGREGATE.chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </v-card>
    <v-card hover v-for="(review, index) in reviewsData.reviews" style="margin-bottom: 12px; width: 100%" :key="index">
      <div style="padding: 12px">
        <span style="font-size: 14px">{{review.snippet}}</span>
        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin-top: 20px">
          <span style="font-size: 12px">{{review.date}}</span>
          <div style="display: flex;flex-direction: row;align-items: center; justify-content: center">
            <span style="font-size: 22px;margin-right: 8px">{{getEmoji(review.sentiment.textSentiment)}}</span>
            <div v-if="review.likes > 0" style="display: flex;flex-direction: row;align-items: center; justify-content: center"><v-icon color="red" style="margin-right: 4px">{{heartIcon}}</v-icon><span style="font-size: 14px">{{review.likes}}</span></div>
          </div>
        </div>
      </div>
      <v-progress-linear :value="review.sentiment.percentage" :color="getProgressColor(review.sentiment.percentage)" height="25"><strong>{{ review.sentiment.percentage }}%</strong></v-progress-linear>
    </v-card>
  </div>
  <LinearLoader v-else></LinearLoader>
</div>
</section>
</template>

<script>
import {mdiCardsHeart} from '@mdi/js';
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import LinearLoader from "@/components/Common/LinearLoader";
import BuildStatusPage from "./BuildStatusPage";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "GmapReviewPage",
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    color: {
      type: String,
      default: '#000000'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      heartIcon: mdiCardsHeart,
      legendArray: [{
        title: 'Good',
        iconPath: 'good.svg'
      },
        {
          title: 'Neutral',
          iconPath: 'neutral.svg'
        },
        {
          title: 'Bad',
          iconPath: 'angry.svg'
        }
      ],
      chartOptions: {
        scales: {
          x: {
            ticks: {
              font: {
                size: 22
              }
            }
          }
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          }
        }
      },
    }
  },
  components: {
    BuildStatusPage,
    LinearLoader,
    Bar,
  },

  created() {
    if (!this.pluginsData.reviews.enabled) {
      this.$store.commit('setError', 'Reviews plugin not enabled');
      this.$router.push(`/${this.$route.params.storeId}/error`);
    }
  },
  methods: {
    getEmojiLink(mood) {
      if (mood === 'Good') {
        return 'good.svg';
      }
      if (mood === 'Neutral') {
        return 'neutral.svg';
      }
      if (mood === 'Bad') {
        return 'bad.svg';
      }
    },
    getEmoji(mood) {
      if (mood === 'Good') {
        return "😍"
      }
      if (mood === 'Neutral') {
        return "😐"
      }
      if (mood === 'Bad') {
        return "😡"
      }
    },

    getProgressColor(percentage) {

      if (!percentage) return "#000000"

      if (percentage < 33)
        return '#F44336'
      else if (percentage < 66)
        return '#FF9800'
      else
        return '#4CAF50'

    },
  },
  computed: {
    reviewsData() {
      return this.$store.getters.reviewsData;
    },
    pluginData() {
      return this.$store.getters.pluginData;
    }
  }
}
</script>

<style scoped>
.reviews-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.card-container {
  padding: 16px;
  width: 100%;
  margin-bottom: 16px;
}
</style>