<template>
<section style=" height: 100%;background-color: #f2f7ff" class="default-font">
  <v-main v-if="reportData">
    <div style="padding-top: 12px; padding-left: 12px; font-size: 16px; font-weight: 600">Overall Sales</div>
    <div class="stats-container">
      <v-card class="card-container" flat color="#C8E6C9">
        <span style="font-size: 16px; font-weight: 600; color: #1B5E20">Today</span>
        <span style="font-size: 36px;line-height:42px; font-weight: 600; color: #1B5E20">{{reportData.itemsPurchasedData.today.data.totalPurchased}}</span>
        <span style="font-size: 14px; color: #1B5E20">items</span>
      </v-card>
      <v-card style="margin-left: 12px" flat color="#F0F4C3" class="card-container">
        <span style="font-size: 16px; font-weight: 600;color: #827717;text-align: center">This month</span>
        <span style="font-size: 36px;line-height:42px; font-weight: 600; color: #827717">{{reportData.itemsPurchasedData.currentMonth.data.totalPurchased}}</span>
        <span style="font-size: 14px; color: #827717">items</span>
      </v-card>
    </div>
      <TopProducts></TopProducts>
    <div class="menu-container default-font">
      <Menu v-if="reportData && this.reportData.itemsPurchasedData" :items="menuItems" v-on:itemChange="setChartData($event)"></Menu>
    </div>
    <div v-if="currentTableData && currentTableData.items.length > 0" style="overflow-x: auto;position: relative;">
      <table style="width: max-content; border: 1px solid #e6e6e6; margin-bottom: 16px;">
        <tr>
          <th class="sticky-col">Product Name</th>
          <th>Price</th>
          <th>Stock Status</th>
          <th>Quantity</th>
          <th>In Stock</th>
          <th v-if="dataType === 'This Month' || dataType === 'This Week'">Purchase date</th>
        </tr>
        <tr v-for="(item, index) in currentTableData.items" :key="index">
          <td class="sticky-col">{{item.name}}</td>
          <td>{{item.price}}</td>
          <td>
            <div :style="{
          'display': 'flex',
          'align-items': 'center',
          'justify-content': 'center',
          'border-radius': '20px',
          'background-color': checkStock(item)[1],
          'color': checkStock(item)[0],
          'padding': '4px 8px',
          'font-weight': '600'
          }">{{checkStock(item)[2]}}</div>
          </td>
          <td>{{item.qty}}</td>
          <td>{{item.stock !== null ? item.stock : 'NA'}}</td>
          <td v-if="dataType === 'This Month' || dataType === 'This Week'">{{item.orderDate}}</td>
        </tr>
      </table>
    </div>
    <div v-else class="empty-order">
      <span>No Orders Available</span>
    </div>
  </v-main>
  <LinearLoader v-else></LinearLoader>
</section>
</template>

<script>
import Menu from "@/components/Common/Menu";
import LinearLoader from "@/components/Common/LinearLoader";
import TopProducts from "@/components/TopProducts/TopProducts";
export default {
  name: "StockCountScreen",
  components: {TopProducts, LinearLoader, Menu},
  data() {
    return {
      menuItems: ['Today','Yesterday','This Week', 'This Month'],
      currentTableData: null,
      dataType: null
    }
  },
  created() {
    if (!this.pluginsData.reports.enabled) {
      this.$store.commit('setError', 'Report plugin not enabled');
      this.$router.push(`/${this.$route.params.storeId}/error`);
    }
    if (this.reportData) this.setChartData(this.menuItems[0]);
  },
  computed: {
    storeData() {
      return this.$store.getters.storeData;
    },
    reportData() {
      return this.$store.getters.reportData;
    },
    pluginsData() {
      return this.$store.getters.pluginData;
    }
  },
  methods: {
    checkStock(item) {
      if (item.inStock && item.stock) {
        return ['#00C853','#C8E6C9', 'In Stock']
      } else if (item.inStock && !item.stock) {
        return ['#FFA000','#FFECB3','Not Activated']
      } else return ['#DD2C00', '#FFCCBC', 'Not In Stock']
    },
    hexToRgb() {
      const hex = this.storeData.store_theme_color;
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return (
          "rgba(" +
          parseInt(result[1], 16) +
          ", " +
          parseInt(result[2], 16) +
          ", " +
          parseInt(result[3], 16) +
          ",0.1)"
      );
    },
    setChartData(type) {
      if (type === 'Today') this.currentTableData = this.reportData.itemsPurchasedData.today;
      if (type === 'Yesterday') this.currentTableData = this.reportData.itemsPurchasedData.yesterday;
      if (type === 'This Week') {
        this.currentTableData = this.reportData.itemsPurchasedData.currentWeek;
        this.dataType = type;
      }
      if (type === 'This Month') {
        this.currentTableData = this.reportData.itemsPurchasedData.currentMonth;
        this.dataType = type;
      }
    }
  }
}
</script>

<style scoped>
table {
  background-color: white;
}
th, td {
  padding: 10px 16px;
}
th {
  font-size: 16px;
}
td {
  color: #86888A;
  font-size: 13px;
  text-align: center;
  word-break: break-word;
}
.empty-order {
  width: 420px;
  height: 300px; display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600
}
.sticky-col {
  position: sticky;
  background-color: white;
  left: 0;
  text-align: left;
  border-right: 1px solid #e6e6e6;
  max-width: 160px;
}
.menu-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 16px 16px 16px;
}
.stats-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
}
.card-container {
  padding: 16px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 100%;
}

@media only screen and (max-width: 1025px) {
  .empty-order {
    width: 100vw;
  }
}
</style>