<template>
  <div>

    <div style="width: 100%; display: flex; flex-direction: column; justify-content: flex-end;padding: 16px">
      <div class="chart-title-doughnut">
        <h3>Aggregate Order Status - Pie</h3>
      </div>

      <div class="bar-selector mb-2 default-font" style="width: max-content; align-self: end; margin-top: 16px">
          <Menu :items="pieTypes" v-on:itemChange="setChartData($event)"></Menu>
        <!--        <v-select style="font-size: 12px" dense height="10px" class="default-font" :items="pieTypes" @change="setChartData" v-model="defaultChart"   outlined/>-->
      </div>
    </div>
    <Doughnut
        :chart-data="currentChartData"
        :chart-options="chartOptions"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
    ></Doughnut>
    <div class="legends">
      <ul class="legends-list default-font">
        <li v-for="i in 5" :key="i" class="legend-item">
          <div :style="{backgroundColor: currentChartData.datasets[0].backgroundColor[i - 1]}" class="legend-color"></div>
          <p class="legend-name default-font-semi-bold">{{currentChartData.labels[i - 1]}}
<!--            <span style="margin-left: auto">:</span>-->
          </p>
          <p>{{numFormatter(currentChartData.datasets[0].data[i - 1])}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import numberFormatterMixin from "../../mixins/numberFormatterMixin";
import { Doughnut } from 'vue-chartjs/legacy';
import Menu from "@/components/Common/Menu";

import { Chart as ChartJS, Title, Tooltip, Legend, DoughnutController,ArcElement, CategoryScale } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, DoughnutController,ArcElement, CategoryScale);
export default {
  name: "DoughnutChart",
  mixins: [numberFormatterMixin],
  components: {
    Menu,
    Doughnut
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
  },
  data () {
    return {
      defaultChart: "Current Month",
      chartData: {
        datasets: [{
          data: [40, 20, 12],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
          ],
          hoverOffset: 15,
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        layout: {
          padding: 16
        },
        hoverOffset: 4
      },
      currentChartData: null,
      pieTypes: ["Current Month", "Last Month", "Last 3 Months", "Current Week", "Last Week"],
    }
  },

  created() {
    this.currentChartData = this.pieChartData.currentMonth.chartData;
  },

  methods: {
    setChartData(type) {
      if (type === "Current Month") this.currentChartData = this.pieChartData.currentMonth.chartData
      if (type === "Last 3 Months") this.currentChartData = this.pieChartData.lastThreeMonths.chartData
      if (type === "Last Month") this.currentChartData = this.pieChartData.lastMonth.chartData
      if (type === "Current Week") this.currentChartData = this.pieChartData.currentWeek.chartData
      if (type === "Last Week") this.currentChartData = this.pieChartData.lastWeek.chartData
    }
  },
  // mounted() {
  //   this.renderChart(this.chartData, this.chartOptions)
  // }

  computed: {
    pieChartData() {
      return this.$store.getters.reportData.pieChartData;
    },
  }
}
</script>

<style scoped>

.legends {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.legend-values {
  list-style: none;
  padding-left: 0 !important;
}

.legend-values p{
  list-style: none;
  margin: 0;
  padding-left: 0;
}

.legend-name {
  width: 100%;
  display: flex;
}

.legend-barrier {
  width: 1px;
  height: 110px;
  background-color: #e1e1e1;
}

.legends-list {
  width: 100%;
  padding: 10px 24px 20px 24px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  padding: 0.4rem;
}

.legend-item:last-of-type {
  border-bottom: none;
}



.legend-item p{
  padding: 0;
  margin: 0;
}


.legend-color {
  height: 13px;
  width: 15px;
}

.chart-title-doughnut {
  text-align: center;
  display: block;
}

</style>