<template>
<div class="loader-screen-container">
  <div style="margin-bottom: 16px; font-size: 18px; font-weight: bold; display: flex; flex-direction: column; align-items: center">
<v-img height="250" width="250" style="margin-bottom: 16px" :src="require('@/assets/img/loading_svg.svg')"/>
    <span>Generating data...</span>
    <span style="font-size: 14px">This may take a while...</span>
  </div>
  <v-progress-linear
      color="#3f51b5"
      indeterminate
      rounded
      height="6"
      style="width: 200px"
  ></v-progress-linear>
</div>
</template>

<script>
export default {
  name: "LinearLoader"
}
</script>

<style scoped>
.loader-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;
  height: 100%;
  overflow: hidden;
}
@media only screen and (max-width: 1025px) {
  .loader-screen-container {
    width: 100%;
  }
}
</style>