<template>
  <section class="store-stats">
    <h3>Store Statistics</h3>
    <v-row style="margin-top: 16px">
      <v-col style="padding: 8px" v-for="(item, index) in statsData" :key="index" :cols="index === statsData.length - 1 ? 12 : 6">
        <div class="status-counts changes-grid-item">
          <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center; width: 100%">
            <span style="font-size: 20px; font-weight: bolder">{{numFormatter(item.value)}}</span>
            <span style="font-size: 14px; color: #86888A; font-weight: 500">{{item.title}}</span>
          </div>
          <div :style="{'border-radius': '50%', 'background-color': item.color, 'padding': '10px'}">
            <v-img height="32" width="32" :src="item.iconPath"></v-img>
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>

import numberFormatterMixin from "../../mixins/numberFormatterMixin";

export default {
  name: "StoreStats",
  mixins: [numberFormatterMixin],
  data() {
    return {
      statsData: [],
    }
  },
  created() {
    this.statsData =  [
      {
        title: 'Pending',
        value: this.storeStatsData.TOTAL_STATUS_COUNTS.PENDING,
        color: '#EDE7F6',
        iconPath: require('@/assets/img/p_order3.svg')
      },
      {
        title: 'Delivered',
        value: this.storeStatsData.TOTAL_STATUS_COUNTS.DELIVERED,
        color: '#FFF8E1',
        iconPath: require('@/assets/img/delivered_order.svg')
      },
      {
        title: 'Prepaid',
        value: this.storeStatsData.PAYMENT_TYPE_COUNTS.PREPAID,
        color: '#E3F2FD',
        iconPath: require('@/assets/img/prepaid.svg')
      },
      {
        title: 'COD',
        value: this.storeStatsData.PAYMENT_TYPE_COUNTS.COD,
        color: '#E0F7FA',
        iconPath: require('@/assets/img/cod.svg')
      },
      {
        title: 'Store Views',
        value: this.storeStatsData.STORE_STATS.STORE_VIEWS,
        color: '#FFF3E0',
        iconPath: require('@/assets/img/view.svg')
      },
      {
        title: 'Item Views',
        value: this.storeStatsData.STORE_STATS.PRODUCT_VIEWS_AGGREGATE,
        color: '#FBE9E7',
        iconPath: require('@/assets/img/product.svg')
      },

      {
        title: 'Pos Total',
        value: this.storeStatsData.STORE_STATS.POS.POS_TOTAL,
        color: '#E0F2F1',
        iconPath: require('@/assets/img/pos_total.svg')
      },

      {
        title: 'Pos Usage',
        value: this.storeStatsData.STORE_STATS.POS.POS_USAGE,
        color: '#FFEBEE',
        iconPath: require('@/assets/img/pos_usage.svg')
      },

      {
        title: 'Revenue',
        value: this.storeStatsData.STORE_STATS.REVENUE,
        color: '#E8F5E9',
        iconPath: require('@/assets/img/revenue.svg')
      },
    ]
  },
  computed: {
    storeStatsData() {
      return this.$store.getters.reportData.allStats;
    }
  }
};
</script>

<style scoped>
.store-stats {
  margin-top: 1rem;
}

.changes-grid-item {
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 0.8rem;
  transition: all 0.3s;
}

.changes-grid-item:hover {
  transform: scale(1.05);
}
.status-counts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}
</style>