<template>
  <div style="background-color: white;border-radius: 16px;margin-bottom: 12px; padding: 16px">
    <div class="single-comparison">
      <div class="chart-title">
        <h3>{{title}}</h3>
      </div>
      <div class="single-comparison-bar">
        <Bar
          :chart-options="barChartOptions"
          :chart-data="comparisonData.chartData"
          :chart-id="barChartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </div>
      <div class="single-comparison-doughnut">
        <Doughnut
          :chart-data="comparisonData.chartData"
          :chart-options="doughnutChartOptions"
          :chart-id="doughnutChartId"
          :dataset-id-key="datasetIdKey"
        ></Doughnut>
      </div>
      <ul class="bar-chart__changes-list">
        <li v-for="(value,name,index) in comparisonData.data" :key="name" class="bar-chart__changes-item">
          <div :style="{backgroundColor: value.color}" class="changes-item-color"></div>
          <p class="changes-item-name default-font-semi-bold">{{name}}</p>
          <span  class="changes-item-value ">{{numFormatter(value.value)}}({{value.percentagne}}%)</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import numberFormatterMixin from "../../mixins/numberFormatterMixin";
import { Bar , Doughnut } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, DoughnutController,ArcElement, CategoryScale, LinearScale } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, DoughnutController,ArcElement,);

export default {
  name: "SingleComparisonChart",
  mixins: [numberFormatterMixin],
  components: {
    Bar,
    Doughnut,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    comparisonData: {
      type: Object,
      default: null,
    },
    barChartId: {
      type: String,
      default: 'bar-chart'
    },
    doughnutChartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    color: {
      type: String,
      default: '#000000'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartData: {
        labels: [ 'January', 'February', 'March', 'April' ],
        datasets: [
          {
            label: 'Sample DataSet',
            data: [40, 20, 12, 16],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(255, 205, 86, 0.2)',
            ]
          },

        ]
      },
      barChartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
        }

      },
      doughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        },
        layout: {
          padding: 16
        },
        hoverOffset: 15
      },
    }
  }
};
</script>

<style scoped>
.bar-chart__changes-list {
  padding-left: 0;
  list-style: none;
  width: 100%;
}

.changes-item-value {
  margin-left: auto;
  font-size: 0.9rem;
}

.changes-item-name {
  font-size: 0.9rem;
}

.changes-item-color {
  width: 13px;
  height: 13px;
  background-color: red;
  margin-right: 0.4rem;
}

.bar-chart__changes-item {
  display: flex;
  align-items: center;
  border-bottom: 1px #e1e1e1 solid;
  padding: 6px;
}

.bar-chart__changes-item:last-of-type {
  border-bottom: none;
}

.bar-chart__changes-item p{
  margin: 0;
  padding: 0;
}
</style>