import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import '@/assets/global.css';
import VueMeta from 'vue-meta'
import store from "./store";
import VueAWN from "vue-awesome-notifications"
import VueCookies from 'vue-cookies'
import router from "./router/index"

Vue.use(VueMeta)
Vue.use(VueAWN, {
  position: 'top-right'
})
Vue.use(VueCookies)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
