<template>
  <section class="changes-main">
    <div style="margin-top: 24px" class="changes-header">
      <h3>Trends</h3>
      <Menu :items="listOptions" v-on:itemChange="selectChangeData($event)"></Menu>
    </div>
    <div class="changes-gird-main">
      <ul class="changes-grid">
        <li v-for="(value , name, index) in currentChangeDataSelected" :key="index" class="changes-grid-item">
          <div class="item-header">
            <div :style="{backgroundColor: convertHexToRGBA(value.color, 0.2)}" class="item-icon-container">
              <v-icon :color="value.color">{{iconsArray[index]}}</v-icon>
            </div>
            <div class="item-name">
              <h4>{{name}}</h4>
              <p>Orders</p>
            </div>
          </div>
          <div class="item-body">
            <h2>{{numFormatter(currentChartDataSelected.chartData.datasets[0].data[index])}}</h2>
            <div class="item-percentage">
              <v-icon color="#23C552" v-if="value.percentage > 0" size="20">{{icons.topRightArrow}}</v-icon>
              <v-icon color="#F84F31" v-else-if="value.percentage < 0" size="20">{{icons.bottomRightArrow}}</v-icon>
              <v-icon v-else size="20">{{icons.rightArrow}}</v-icon>
              <p :style="{color: value.percentage === 0 ? '#000000' : value.percentage > 0  ? '#23C552' : '#F84F31'}">{{value.percentage}}%</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import numberFormatterMixin from "../../mixins/numberFormatterMixin";
import {convertHexToRGBA} from "../../utils"
import { mdiClipboardCheckOutline, mdiArrowTopRight, mdiArrowBottomRight, mdiArrowRight, mdiPackageVariantClosedRemove, mdiClipboardTextMultipleOutline, mdiTruckOutline, mdiPackageVariantClosedCheck } from "@mdi/js"
import Menu from "../Common/Menu";
export default {
  name: "barchartChanges",
  components: { Menu },
  mixins: [numberFormatterMixin],
  data() {
    return {
      listOptions:["Current Month", "Last Month", "Current Week", "Last Week"],
      currentChangeDataSelected: null,
      currentChartDataSelected: null,
      optionSelected: "Current Month",
      convertHexToRGBA: convertHexToRGBA,
      icons: {
        topRightArrow: mdiArrowTopRight,
        rightArrow: mdiArrowRight,
        bottomRightArrow: mdiArrowBottomRight
      },
      iconsArray: [mdiPackageVariantClosedCheck, mdiClipboardTextMultipleOutline, mdiClipboardCheckOutline, mdiPackageVariantClosedRemove, mdiTruckOutline ]
    }
  },

  created() {

    const orderStatusArray = ["DELIVERED","PENDING","ACCEPTED", "DECLINED",  "SHIPPED"];

    for (let data in this.barChartChanges) {

      this.barChartChanges[data] = orderStatusArray.reduce((acc, key) => {
        acc[key] = this.barChartChanges[data][key]

        return acc
      }, {})

    }

    this.currentChangeDataSelected = this.barChartChanges.currentVsLastMonthData
    this.currentChartDataSelected = this.pieChartData.currentMonth
  },

  methods: {
    selectChangeData(type) {
      if (type === "Current Month") {
        this.currentChangeDataSelected = this.barChartChanges.currentVsLastMonthData
        this.currentChartDataSelected = this.pieChartData.currentMonth
      }
      if (type === "Last Month") {
        this.currentChangeDataSelected = this.barChartChanges.lastVsCurrentMonthData
        this.currentChartDataSelected = this.pieChartData.lastMonth
      }
      if (type === "Current Week") {
        this.currentChangeDataSelected = this.barChartChanges.currentVsLastWeek
        this.currentChartDataSelected = this.pieChartData.currentWeek
      }
      if (type === "Last Week") {
        this.currentChangeDataSelected = this.barChartChanges.lastVsCurrentWeek
        this.currentChartDataSelected = this.pieChartData.lastWeek
      }
    }
  },

  computed: {
    barChartChanges() {
      return this.$store.getters.reportData.barChartData.changes;
    },
    pieChartData() {
      return this.$store.getters.reportData.pieChartData
    }
  },
};
</script>

<style>

.changes-main {
  margin-top: 1rem;
}

.changes-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.changes-gird-main {
  margin-top: 1rem;
}

.changes-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, max-content);
  list-style: none;
  gap: 1rem;
  padding-left: 0 !important;
}

.changes-grid-item {
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
  border-radius: 10px;
  padding: 1rem;
  transition: all 0.3s;
}

.changes-grid-item:hover {
  transform: scale(1.05);
}

.changes-grid-item:first-of-type {
  grid-column: 1 / -1;
}

.item-icon-container {
  width: max-content;
  padding: 0.5rem;
  border-radius: 50%;
}

.item-header {
  display: flex;
  gap: 0.7rem;
  align-items: center;
}

.item-name {
  font-size: 0.9rem;
}

.item-name p {
  margin: 0;
  font-size: 0.7rem;
}

.item-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.7rem;
}

.item-body p {
  margin: 0;
  padding: 0;
}

.item-body h2 {
  margin-left: 0.3rem;
}

.item-percentage p {
  font-size: 0.9rem;
}


</style>