<template>
<div>
  <div class="chart-title">
    <h3>Aggregate Order Status - Bar</h3>
  </div>
  <div style="width: 100%; display: flex; flex-direction: row; justify-content: flex-end">

    <div class="bar-selector mb-2 default-font" style="width: max-content">
<!--      <v-select style="font-size: 12px" dense height="10px" class="default-font" @change="setChartData" v-model="defaultChart" :items="barTypes" outlined/>-->
      <Menu :items="barTypes" v-on:itemChange="setChartData($event)"></Menu>
    </div>
  </div>
  <Bar
    :chart-options="chartOptions"
    :chart-data="currentChartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import Menu from "@/components/Common/Menu";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
export default {
  name: "BarChart",
  components: {
    Menu,
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    color: {
      type: String,
      default: '#000000'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      defaultChart: "Current Month",
        chartData: {
        labels: [ 'January', 'February', 'March', 'April' ],
        datasets: [
          {
            label: 'Sample DataSet',
            data: [40, 20, 12, 16],
            backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 159, 64, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            'rgba(255, 205, 86, 0.2)',
            ]
          },

        ]
        },
        chartOptions: {
          responsive: true,
        },
      currentWeekData: {
          data: {
            ACCEPTED: [],
            DELIVERED: [],
            DECLINED: [],
            PENDING: [],
            SHIPPED: [],
            TOTAL_COUNT: [],
        },
        chartData: {
            labels: [],
            datasets: [],
        }
    },
      lastWeekData: {
          data: {
            ACCEPTED: [],
            DELIVERED: [],
            DECLINED: [],
            PENDING: [],
            SHIPPED: [],
            TOTAL_COUNT: [],
        },
        chartData: {
            labels: [],
            datasets: [],
        }
    },
      currentMonthData: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Current Month",
              data: [],
              backgroundColor: [],
            }
          ],
        }
      },
      lastMonthData: {
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Current Month",
              data: [],
              backgroundColor: [],
            }
          ],
        }
      },
      barTypes: ["Current Month", "Last Month", "Last 3 Months", "Current Week", "Last Week"],
      currentChartData: null,
}
  },

  created() {
    this.currentChartData = this.barChartData.currentMonth.chartData;
  },

  methods: {
    setChartData(type) {
      if (type === "Current Month") this.currentChartData = this.barChartData.currentMonth.chartData
      if (type === "Last 3 Months") this.currentChartData = this.barChartData.lastThreeMonths.chartData
      if (type === "Last Month") this.currentChartData = this.barChartData.lastMonth.chartData
      if (type === "Current Week") this.currentChartData = this.barChartData.currentWeek.chartData
      if (type === "Last Week") this.currentChartData = this.barChartData.lastWeek.chartData
    }
  },


  computed:  {
    barChartData() {
     return this.$store.getters.reportData.barChartData
    },
    backgroundColors() {
      return this.$store.getters.backgroundColors
    },
    lastThreeMonthsData() {
      return this.$store.getters.lastThreeMonthsData
    }
  }
}
</script>

<style scoped>

</style>