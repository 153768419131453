<template>
  <div>
    <div style="width: 100%;">
      <div class="chart-title">
        <h3>Individual Order Status</h3>
      </div>
      <div class="bar-selector mb-2 default-font">
        <!--      <v-select style="font-size: 12px" dense height="10px" class="default-font" @change="setChartData" v-model="defaultChart" :items="barTypes" outlined/>-->
        <Menu style="width: 100%" :items="lineTypes" v-on:itemChange="lineType = $event"></Menu>
        <Menu style="width: 100%" :items="statusArray" v-on:itemChange="statusType = $event"></Menu>
      </div>
    </div>
    <Bar
      :chart-options="chartOptions"
      :chart-data="currentChartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import Menu from "@/components/Common/Menu";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
export default {
  name: "StatusBarChart",
  components: {
    Menu,
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    color: {
      type: String,
      default: '#000000'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      defaultChart: "Current Month",
      chartData: {
        labels: [ 'January', 'February', 'March', 'April' ],
        datasets: [
          {
            label: 'Sample DataSet',
            data: [40, 20, 12, 16],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(255, 159, 64, 0.2)',
              'rgba(255, 205, 86, 0.2)',
              'rgba(255, 205, 86, 0.2)',
            ]
          },

        ]
      },
      chartOptions: {
        responsive: true,

      },
      currentChartData: {
        labels: [],
        datasets: [],
      },
      lineTypes: ["Last 3 Months", "Current Week", "Last Week"],
      statusArray: ["ACCEPTED", "DECLINED", "PENDING", "SHIPPED", "DELIVERED"],
      lineType: "Last 3 Months",
      statusType: "ACCEPTED",
    }
  },

  created() {
    this.chartDataBeingUsed = this.lineChartData.lastThreeMonths.chartData;
    this.currentChartData.labels = this.lineChartData.lastThreeMonths.chartData.labels
    this.currentChartData.datasets.push(this.lineChartData.lastThreeMonths.chartData.datasets.find(dataset => dataset.label === this.statusType))
  },

  watch: {
    statusType(val) {
      this.versusArrayTwo = this.statusArray.filter(status => status !== val)
      this.updateStatusType(val)
    },
    lineType(val) {
      if (val === "Last 3 Months") this.updateGraphType(val, this.lineChartData.lastThreeMonths.chartData)
      if (val === "Current Week") this.updateGraphType(val, this.lineChartData.currentWeek.chartData)
      if (val === "Last Week") this.updateGraphType(val, this.lineChartData.lastWeek.chartData)
    }
  },

  methods: {
    updateGraphType(type, typeChartData) {
      this.chartDataBeingUsed = typeChartData;
      this.currentChartData.labels = typeChartData.labels
      this.currentChartData.datasets = [];
      this.currentChartData.datasets.push(typeChartData.datasets.find(dataset => dataset.label === this.statusType))
    },
    updateStatusType(type) {
      this.$set(this.currentChartData.datasets, 0, this.chartDataBeingUsed.datasets.find(dataset => dataset.label === type))
    },
  },


  computed: {
    lineChartData() {
      return this.$store.getters.reportData.lineChartData;
    }
  }
}
</script>

<style scoped>

.bar-selector {
  display: flex;
  justify-content: center;
  gap: 1.6rem;
}

.chart-title {
  text-align: center;
  margin-bottom: 1rem;
}

</style>