<template>
  <v-app style="width: 100%">
    <v-main v-if="reportData" style="background-color: #f2f7ff;padding: 16px">
      <div class="store-stats-data">
        <StoreStats />
      </div>
      <div class="bar-chart">
        <BarchartChanges />
        <div style="background-color: white;border-radius: 16px;margin-bottom: 12px;padding: 16px;margin-top: 24px">
          <StatusBarChart />
        </div>
        <div style="background-color: white;border-radius: 16px;margin-bottom: 12px;padding: 16px;margin-top: 24px">
          <BarChart></BarChart>
        </div>
      </div>
      <div style="background-color: white;border-radius: 16px;margin-top: 24px;">
        <DoughnutChart></DoughnutChart>
      </div>
      <div style="background-color: white;border-radius: 16px;padding: 16px;margin-top: 24px">
        <LineChart></LineChart>
      </div>
      <div class="single-comparison">
        <SingleComparisonChart title="Prepaid Vs Other Orders" :comparison-data="reportData.prepaidVsOtherOrders"/>
        <SingleComparisonChart title="Processed Vs Declined Orders" :comparison-data="reportData.processedVsDeclinedOrders"/>
        <SingleComparisonChart title="Manual Vs Normal Orders" :comparison-data="reportData.manualVsNormalOrders"/>
      </div>
    </v-main>
    <LinearLoader v-else></LinearLoader>
  </v-app>
</template>

<script>
import Toolbar from "@/components/Toolbar/Toolbar";
import LinearLoader from "@/components/Common/LinearLoader";
import BarChart from "@/components/BarCharts/BarChart";
import DoughnutChart from "@/components/DougnutCharts/DoughnutChart";
import LineChart from "@/components/LineCharts/LineChart";
import BarchartChanges from "../components/BarCharts/barchartChanges";
import SingleComparisonChart from "../components/SingleComparisonChart/SingleComparisonChart";
import StatusBarChart from "../components/StatusBarChart/StatusBarChart";
import StoreStats from "../components/StoreStats/StoreStats";
export default {
  name: "Main",
  components: { StoreStats, StatusBarChart, SingleComparisonChart, BarchartChanges, LineChart, DoughnutChart, BarChart, Toolbar, LinearLoader},
  created() {
    if (!this.pluginsData.reports.enabled) {
      this.$store.commit('setError', 'Report plugin not enabled');
      this.$router.push(`/${this.$route.params.storeId}/error`);
    }
  },
  computed: {
    reportData() {
      return this.$store.getters.reportData
    },
    storeData() {
      return this.$store.getters.storeData;
    },
    pluginsData() {
      return this.$store.getters.pluginData;
    }
  }
}
</script>

<style scoped>
.store-stats-data {
  margin-top: 1rem;
}

.single-comparison {
  margin-top: 1.5rem;
}
</style>