<template>
  <div v-if="topProducts && topProducts.length > 0">
  <div style="margin-left: 12px; font-size: 16px; font-weight: 600; margin-bottom: 16px;margin-top: 16px"><span>Top Products</span></div>
    <div class="flex-row" style="display: flex; flex-direction: row; overflow-x: auto;height: auto; overflow-y: auto">
      <div v-for="item in topProducts" :key="item.id" style="margin-left: 10px">
        <v-card hover outlined class="card-container">
          <v-img height="60" width="60" :src="getImgUrl(item.img)"></v-img>
        </v-card>
        <div
            style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
        >
                  <span
                      class="item-name"
                  >
                    {{ item.name }}
                  </span>
        </div>
      </div>
      <div style="margin-right: 10px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopProducts",
  computed: {
    topProducts() {
      return this.$store.getters.reportData.allStats.TOP_PRODUCTS;
    }
  },
  methods: {
    getImgUrl(ITEM_IMG_URL_1, w = 300, h = 300, isGrey) {
      let url = "https://ultron.zyadashop.io/xenon/";
      if (isGrey) url = "https://ultron.zyadashop.io/xenon/grey/";
      if (ITEM_IMG_URL_1 && ITEM_IMG_URL_1.length < 10)
        return "/img/image_blank.webp";
      return url + encodeURIComponent(ITEM_IMG_URL_1) + `/${w}/${h}`;
    },
  }
}
</script>

<style scoped>

.disableScroll::-webkit-scrollbar {
  display: none;
}

.disableScroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.card-container {
  border: 1px solid #e6e6e6;padding: 4px;
}

.item-name {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>